import React, { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

interface Donation {
  amount: number;
  name: string;
  message: string;
}

interface SocketContextProps {
  donation: Donation | null;
}

const SocketContext = createContext<SocketContextProps>({
  donation: null,
});

export const useSocket = () => useContext(SocketContext);

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [donation, setDonation] = useState<Donation | null>(null);

  useEffect(() => {
    const SOCKET_URL = process.env.REACT_APP_DONATION_SOCKET_URL ||  'wss://api.uzchess.glmv.dev/socket/donation';
    const socket: Socket = io(SOCKET_URL, {
      transports: ['websocket'],
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
    });

    socket.on('connect', () => {
      console.log('Connected to Socket.IO server');
    });

    socket.on('disconnect', (reason) => {
      console.log(`Disconnected: ${reason}`);
    });

    socket.on('newDonation', (data: Donation) => {
      console.log('Received donation:', data);
      setDonation(data);
      
      setTimeout(() => setDonation(null), 5000); 
    });

    socket.io.on('reconnect_attempt', (attempt: number) => {
      console.log(`Reconnection attempt ${attempt}`);
    });

    socket.io.on('reconnect_failed', () => {
      console.error('Reconnection failed');
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ donation }}>
      {children}
    </SocketContext.Provider>
  );
};
