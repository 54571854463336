
import {createContext, type ReactNode, useState, useEffect} from "react";

interface IContext {
  isAuthenticated: boolean;
  setIsAuthenticated: (val: boolean) => void;
  role: string | null;
  setRole: (role: string | null) => void;
}

export const AuthContext = createContext<IContext>({
  isAuthenticated: false,
  setIsAuthenticated: () => { },
  role: null,
  setRole: () => { },
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const token = localStorage.getItem('access');
    const storedRole = localStorage.getItem('role');

    if (token) {
      setIsAuthenticated(true);
      setRole(storedRole);
    } else {
      setIsAuthenticated(false);
      setRole(null);
    }

    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, role, setRole }}>
      {children}
    </AuthContext.Provider>
  );
};
