import { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  BookAudio,
  Swords,
  Newspaper,
  LibraryBig,
  Radio,
  Puzzle,
  Bell,
  Dices,
  ScrollText,
  BarChart2,
  Bot,
  Users,
  User,
  ListVideo,
  LucideView,
  UserRoundCog,
  CircleDollarSign,
  LayoutList,
  Users2,
  Banknote,
} from 'lucide-react';
import { Button } from 'components/ui/button';
import { cn } from 'utils/styleUtils';

import { AuthContext } from 'providers/auth';

interface IProps {
  isSideNavOpen: boolean;
}

const SideNav = ({ isSideNavOpen }: IProps) => {
  const { role } = useContext(AuthContext);

  const menuItems = [
    {
      title: 'Statistika',
      icon: BarChart2,
      link: '/',
      roles: ['moderator', 'admin'],
    },
    {
      title: 'Foydalanuvchilar',
      icon: User,
      link: '/users',
      roles: ['admin'],
    },
    {
      title: 'Mentorlar',
      icon: Users2,
      link: '/mentors',
      roles: ['admin'],
    },
    {
      title: 'Chess Players',
      icon: UserRoundCog,
      link: '/chess-players',
      roles: ['admin'],
    },
    {
      title: 'Pullik tariflar',
      icon: CircleDollarSign,
      link: '/premium-plans',
      roles: ['admin'],
    },
    {
      title: 'Kuponlar',
      icon: Banknote,
      link: '/coupons',
      roles: ['admin'],
    },
    {
      title: 'Kurslar',
      icon: BookAudio,
      link: '/courses',
      roles: ['admin'],
    },
    {
      title: "O'qituvchilar",
      icon: Users,
      link: '/teachers',
      roles: ['admin'],
    },
    {
      title: 'Toifalar',
      icon: LayoutList,
      link: '/categories',
    },
    {
      title: 'Shorts',
      icon: ListVideo,
      link: '/grandmasters',
      roles: ['moderator', 'admin'],
    },
    {
      title: 'Yangiliklar',
      icon: Newspaper,
      link: '/news',
      roles: ['moderator', 'admin'],
    },
    {
      title: 'Kitoblar',
      icon: LibraryBig,
      link: '/books',
      roles: ['admin'],
    },
    {
      title: 'Tahlillar',
      icon: ScrollText,
      link: '/review-games',
      roles: ['moderator', 'admin'],
    },
    {
      title: 'Musobaqalar',
      icon: Swords,
      link: '/tournaments',
      roles: ['admin'],
    },
    {
      title: 'Jonli efir',
      icon: Radio,
      link: '/live-streams',
      roles: ['moderator', 'admin'],
    },
    {
      title: 'Boshqotirmalar',
      icon: Puzzle,
      link: '/puzzles',
      roles: ['admin'],
    },
    {
      title: 'Bildirishnomalar',
      icon: Bell,
      link: '/notifications',
      roles: ['moderator', 'admin'],
    },
    {
      title: 'MasterBot',
      icon: Bot,
      link: '/module-bots',
      roles: ['moderator', 'admin'],
    },
    {
      title: 'Live-Donatlar',
      icon: LucideView,
      link: '/donations',
      roles: ['moderator'],
    },
  ];

  const visibleMenuItems = menuItems.filter(item => {
    if (!item.roles) return true;

    return item.roles.includes(role || '');
  });

  return (
    <aside
      className={cn(
        { 'w-full': isSideNavOpen },
        'sticky top-0 max-w-72 flex flex-col border-solid border-r-2 h-screen'
      )}
    >
      <header className="z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 p-2">
        <Link
          to="/"
          className={`flex h-10 items-center gap-2 px-4 dark:text-white ${
            isSideNavOpen ? 'justify-start' : 'justify-center'
          }`}
        >
          {isSideNavOpen && <h1 className="font-semibold">UzChess</h1>}
          <Dices />
        </Link>
      </header>
      <div className="flex flex-col gap-1 p-2">
        {visibleMenuItems.map((item, index) => (
          <NavLink
            to={item.link}
            key={index}
            className={({ isActive }) =>
              cn({ 'bg-secondary': isActive }, 'dark:text-white rounded block')
            }
          >
            <Button
              variant="ghost"
              className={`w-full ${
                isSideNavOpen ? 'justify-start' : 'justify-center'
              }`}
            >
              <item.icon className="size-5 stroke-[1.3px]" />
              {isSideNavOpen && <span className="ml-3">{item.title}</span>}
            </Button>
          </NavLink>
        ))}
      </div>
    </aside>
  );
};

export default SideNav;
